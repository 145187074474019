<template>
  <v-container
    fluid
    class="my-0 mx-auto px-0 py-3 py-sm-6 grey darken-4 text-center"
  >
    <template v-if="is_mobile">
      <div class="d-flex align-center justify-center white--text mb-1">
        <div class="pr-3">&copy;示说</div>
        <div>沪ICP备18027414号</div>
      </div>
      <div class="d-flex align-center justify-center white--text">
        <div class="pr-1">
          <v-img
            width="12"
            height="13"
            src="/images/police.png"
            class="flex-grow-0"
          />
        </div>
        <div>沪公网安备 31011702004679号</div>
      </div>
    </template>
    <div v-else class="d-inline-flex align-center white--text">
      <div class="pr-3">&copy;示说</div>
      <div class="pr-3">沪ICP备18027414号</div>
      <div class="pr-1">
        <v-img
          width="12"
          height="13"
          src="/images/police.png"
          class="flex-grow-0"
        />
      </div>
      <div>沪公网安备 31011702004679号</div>
    </div>
  </v-container>
</template>
<script>
import Tools from '@/mixins/Tools.js'
export default {
  name: 'PcFooter',
  mixins: [Tools]
}
</script>
