<template>
  <div
    v-if="scrolled"
    class="d-inline"
  >
    <v-btn
      v-if="is_mobile"
      small
      fab
      class="grey darken-4 white--text pa-0 cs-top cs-top-m"
      :width="36"
      :height="36"
      @click="scrollToTop"
    >
      <v-icon
        size="15"
        left
        class="mr-0 mt-n1"
      >
        {{ icons.arrowup }}
      </v-icon>
    </v-btn>
    <v-btn
      v-else
      small
      class="grey darken-4 white--text pa-0 cs-top cs-top-pc"
      :width="48"
      :height="48"
      @click="scrollToTop"
    >
      <div class="d-flex fill-height flex-column align-center justify-center pa-0">
        <v-icon
          size="18"
          left
          class="mr-0 mt-n1"
        >
          {{ icons.arrowup }}
        </v-icon>
        <div>Top</div>
      </div>
    </v-btn>
  </div>
</template>
<script>
import { mapGetters } from "vuex"
import Svgs from '@/mixins/Svgs.js'
import Tools from '@/mixins/Tools.js'
export default {
  name: 'GetTop',
  mixins: [Tools, Svgs],
  computed: {
    ...mapGetters(['scrolled'])
  },
  methods: {
    // 回到顶部
    scrollToTop() {
      window.scrollTo(0, 0);
    }
  }
}
</script>
<style lang="sass" scoped>
.cs-top
  position: fixed
  right: 0
  bottom: 0
  transition: background-color .3s
  opacity: 0.53
  z-index: 11
  &:hover
    background-color: #01BCCB !important
.cs-top-m
  transform: translate(-10%, -200%)
.cs-top-pc
  transform: translate(-10%, -150%)
</style>
