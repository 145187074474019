<template>
  <v-main class="white">
    <cs-alert />
    <pc-header v-if="!is_mobile" />
    <mobile-header v-else />
    <mobile-menu v-if="is_mobile" />
    <router-view :key="$route.path" />
    <pc-footer />
    <get-top v-if="true" />
  </v-main>
</template>
<script>
import { mapGetters } from 'vuex'
import CsAlert from '@/components/CsAlert.vue'
import GetTop from '@/components/GetTop.vue'
import PcHeader from '@/components/PcHeader.vue'
import PcFooter from '@/components/PcFooter.vue'
import MobileHeader from '@/components/MobileHeader.vue'
import MobileMenu from '@/components/MobileMenu.vue'
import { DEVICE_MOBILE } from '@/constants'
import Initial from '@/mixins/Initial.js'
import wScroll from '@/mixins/wScroll.js'
export default {
  name: 'FrontRouterView',
  mixins: [Initial, wScroll],
  components: {
    CsAlert,
    PcHeader,
    MobileHeader,
    MobileMenu,
    PcFooter,
    GetTop
  },
  data() {
    return {
      key_enroll: 'set-enroll'
    }
  },
  computed: {
    ...mapGetters(['device', 'event_id', 'enroll_drawer_width']),
    is_mobile() {
      return this.device === DEVICE_MOBILE
    }
  },
  watch: {
    event_id() {
      // 强行渲染报名表单
      this.key_enroll = `set-enroll${ new Date().getTime() }`
    },
    enroll_drawer_width() {
      // 强行渲染报名表单
      this.key_enroll = `set-enroll${ new Date().getTime() }`
    }
  }
}
</script>
