<template>
  <v-container
    fluid
    :class="['my-0', 'mx-auto', 'pa-0', 'fixed-opacity', {'fixed-show': scrolled}]"
    :style="styleObj"
  >
    <v-row
      no-gutters
      class="align-center align-self-stretch fill-height"
    >
      <v-col
        cols="auto"
        class="my-0 ml-0 mr-5 pa-0"
      >
        <v-icon
          size="26"
          left
          class="ml-3 mt-1 mr-0"
          color="#757575"
          @click="$store.dispatch('app/setMenuDrawer', true)"
        >
          {{ icons.threes }}
        </v-icon>
      </v-col>
      <v-spacer />
      <v-col
        v-if="false"
        cols="auto"
        class="my-0 mr-3 ml-0 pa-0"
      >
        <v-btn
          v-if="!was_enrolled"
          type="primary"
          dense
          light
          depressed
          color="#01BDCB"
          class="white--text"
          width="80"
          height="30"
          @click="$store.dispatch('app/setEnrollDrawer', true)"
        >
          一键预约
        </v-btn>
        <v-btn
          v-else
          type="primary"
          dense
          light
          depressed
          color="#01BDCB"
          class="white--text"
          width="80"
          height="30"
          @click="$store.dispatch('app/setEnrollHavedDrawer', true)"
        >
          <v-icon
            size="18"
            left
            class="mr-0"
          >
            {{ icons.gou }}
          </v-icon>
          已预约
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
import Tools from '@/mixins/Tools.js'
import Svgs from '@/mixins/Svgs.js'
import { SYMPOSIA_MEETUP, MOBILE_HEADER_HEIGHT } from '@/constants.js'
import  { getCookieForSymposia } from '@/util/helpers.js'
export default {
  name: 'MobileHeader',
  mixins: [Tools, Svgs],
  computed: {
    ...mapGetters(['scrolled', 'was_enrolled']),
    styleObj() {
      return {
        height: MOBILE_HEADER_HEIGHT
      }
    }
  },
  mounted() {
    if(getCookieForSymposia(SYMPOSIA_MEETUP)) {
      this.$store.dispatch('app/setWasEnrolled', true)
    } else {
      this.$store.dispatch('app/setWasEnrolled', false)
    }
  }
}
</script>
<style lang="sass" scoped>
.fixed-opacity
  position: fixed
  left: 0
  top: 0
  z-index: 2
  background-color: rgba(255, 255, 255, 1)
  box-shadow: 0 2px 8px 0 rgba(0,0,0,0.07)
.fixed-show
  background-color: rgba(255, 255, 255, 0.98)
</style>
