<template>
  <v-dialog
    :value="alert_show"
    persistent
    max-width="500px"
  >
    <v-card>
      <v-card-title class="text-h5 font-weight-bold">
        <span v-if="alert_type === 'error'" class="red--text text--accent-2">
          错误
        </span>
        <span v-else-if="alert_type === 'success'" class="green--text">
          成功
        </span>
        <span v-else-if="alert_type === 'info'" class="blue--text">
          提示
        </span>
        <span v-else-if="alert_type === 'warning'" class="amber--text">
          警告
        </span>
      </v-card-title>
      <v-card-text class="pt-5 font-weight-medium">
        <span v-if="alert_type === 'error'" class="red--text text--accent-2">
          {{ alert_message }}
        </span>
        <span v-else-if="alert_type === 'success'" class="green--text">
          {{ alert_message }}
        </span>
        <span v-else-if="alert_type === 'info'" class="blue--text">
          {{ alert_message }}
        </span>
        <span v-else-if="alert_type === 'warning'" class="amber--text">
          {{ alert_message }}
        </span>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn
          text
          color="success"
          x-large
          @click="handleClosed"
        >
          知道了
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
  import { mapGetters } from 'vuex'
  export default {
    name: 'CsAlert',
    computed: {
      ...mapGetters(['alert_type', 'alert_show', 'alert_message'])
    },
    methods: {
      handleClosed() {
        this.$store.dispatch('app/setAlertShow', false)
      }
    }
  }
</script>
