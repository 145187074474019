/**
 * 促初始化 监听是否发生滚动事件
 */
import { mapGetters } from 'vuex'
import {
  DESKTOP_HEADER_HEIGHT,
  DEVICE_MOBILE,
  MOBILE_HEADER_HEIGHT
} from '../constants'
export default {
  computed: {
    ...mapGetters(['device'])
  },
  beforeMount() {
    window.addEventListener('scroll', this.handleScrollEvent)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScrollEvent)
  },
  mounted() {
    this.handleScrollEvent()
  },
  methods: {
    handleScrollEvent() {
      let tmp_height = DESKTOP_HEADER_HEIGHT
      if (this.device === DEVICE_MOBILE) {
        tmp_height = MOBILE_HEADER_HEIGHT
      }
      if (parseInt(window.scrollY) > parseInt(tmp_height)) {
        this.$store.dispatch('app/setScrolled', true)
      } else {
        this.$store.dispatch('app/setScrolled', false)
      }
    }
  }
}
