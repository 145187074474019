/**
 * 初始化操作
 */
import {
  DEVICE_MOBILE,
  DEVICE_DESKTOP,
  ENROLL_DRAWER_WIDTH,
  DESKTOP_HEADER_HEIGHT,
  MOBILE_HEADER_HEIGHT,
  SYMPOSIA_MEETUP,
  SYMPOSIA_MEETUP_DAYS
} from '@/constants'
import {
  setCookieForSymposia,
  getTokenByEventIdForCookie
} from '@/util/helpers.js'

const TMP_EVENT_ID = 1163

export default {
  mounted() {
    this.$store.dispatch('app/setDevice', this.isMobile() ? DEVICE_MOBILE : DEVICE_DESKTOP)
    this.$store.dispatch('app/setHeaderHeight', this.isMobile() ? MOBILE_HEADER_HEIGHT : DESKTOP_HEADER_HEIGHT)
    this.$store.dispatch('app/setEventId', TMP_EVENT_ID)
    this.setEnrollDrawerWidth()
    // 如果本地已经存在报名的cookie，优先使用
    if (!getTokenByEventIdForCookie(SYMPOSIA_MEETUP, TMP_EVENT_ID) && this.$route.query.t) {
      setCookieForSymposia(SYMPOSIA_MEETUP, TMP_EVENT_ID + '|' + this.$route.query.t, SYMPOSIA_MEETUP_DAYS)
      this.$store.dispatch('app/setWasEnrolled', true)
    }
  },
  methods: {
    isMobile() {
      return navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
    },
    setEnrollDrawerWidth() {
      const window_width = document.body.offsetWidth
      if (window_width < ENROLL_DRAWER_WIDTH){
        this.$store.dispatch('app/setEnrollDrawerWidth', window_width - 30)
      }
    }
  }
}
