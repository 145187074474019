/**
 * svg操作
 */
 export default {
  data() {
    return {
      icons: {
        gou: 'M9.34685425,18.3643687 C9.28176687,18.4294561 9.20319945,18.4728476 9.12013868,18.4945434 L9.03591297,18.5090073 L9.03591297,18.5090073 L8.95068874,18.5090073 C8.83727833,18.4993647 8.72653064,18.4511518 8.63974747,18.3643687 L3.33644661,13.0610678 C3.14118446,12.8658057 3.14118446,12.5492232 3.33644661,12.353961 L4.04355339,11.6468542 C4.23881554,11.4515921 4.55539803,11.4515921 4.75066017,11.6468542 L8.993,15.889 L19.2463492,5.63644661 C19.4199155,5.46288026 19.6893399,5.44359511 19.8842081,5.57859116 L19.953456,5.63644661 L20.6605627,6.34355339 C20.8558249,6.53881554 20.8558249,6.85539803 20.6605627,7.05066017 L20.6605627,7.05066017 L9.34685425,18.3643687 Z',
        threes: 'M18,13 L18,15 L0,15 L0,13 L18,13 Z M18,8 L18,10 L0,10 L0,8 L18,8 Z M18,3 L18,5 L0,5 L0,3 L18,3 Z',
        arrowup: 'M13.9937085,7.63 L25.307417,18.9437085 L23.8932034,20.3579221 L13.993,10.458 L4.09421356,20.3579221 L2.68,18.9437085 L13.9937085,7.63 Z'
      }
    }
  }
 }
 