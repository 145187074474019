<template>
  <v-container
    fluid
    :class="['my-0', 'mx-auto', 'pa-0', 'fixed-opacity', {'fixed-show': scrolled}]"
  >
    <v-row
      no-gutters
      class="align-center align-self-stretch fill-height mx-auto"
      :style="styleObj"
    >
      <v-col
        cols="auto"
        class="my-0 ml-0 mr-5 pa-0"
      >
        <router-link to='/'>
          <img class="d-block" style="height: 42px;" src="/images/logo.png?2">
        </router-link>
      </v-col>
      <v-spacer />
      <v-col
        cols="auto"
        class="my-0 mx-7 py-0 px-2 align-self-stretch d-flex align-center cs-menu"
        :class="[{'cs-menu-active': website_menu==='index'}]"
      >
        <router-link
          to='/'
          class="text-decoration-none text-body-1"
          :class="[website_menu==='index' ? 'cs-menu-active-a': 'grey--text text--darken-4']"
        >
          首页
        </router-link>
      </v-col>
      <v-col
        v-if="false"
        cols="auto"
        class="my-0 mx-7 pa-0 align-self-stretch d-flex align-center cs-menu"
        :class="[{'cs-menu-active': website_menu==='liveroom'}]"
      >
        <router-link
          to='/liveroom'
          class="text-decoration-none text-body-1"
          :class="[website_menu==='liveroom' ? 'cs-menu-active-a': 'grey--text text--darken-4']"
        >
          直播间
        </router-link>
      </v-col>
      <v-col
        cols="auto"
        class="my-0 mx-7 pa-0 align-self-stretch d-flex align-center cs-menu"
        :class="[{'cs-menu-active': website_menu==='playback'}]"
      >
        <router-link
          to='/playback'
          class="text-decoration-none text-body-1"
          :class="[website_menu==='playback' ? 'cs-menu-active-a': 'grey--text text--darken-4']"
        >
          回放
        </router-link>
      </v-col>
      <v-col
        cols="auto"
        class="my-0 mx-7 pa-0 align-self-stretch d-flex align-center cs-menu"
        :class="[{'cs-menu-active': website_menu==='lecturer'}]"
      >
        <router-link
          to='/lecturer'
          class="text-decoration-none text-body-1"
          :class="[website_menu==='lecturer' ? 'cs-menu-active-a': 'grey--text text--darken-4']"
        >
          嘉宾
        </router-link>
      </v-col>
      <v-col
        cols="auto"
        class="my-0 mx-7 pa-0 align-self-stretch d-flex align-center cs-menu"
        :class="[{'cs-menu-active': website_menu==='agenda'}]"
      >
        <router-link
          to='/agenda'
          class="text-decoration-none text-body-1"
          :class="[website_menu==='agenda' ? 'cs-menu-active-a': 'grey--text text--darken-4']"
        >
          议程
        </router-link>
      </v-col>
      <v-col
        v-if="false"
        cols="auto"
        class="my-0 mr-0 ml-7 pa-0"
      >
        <v-btn
          v-if="!was_enrolled"
          type="primary"
          dense
          light
          depressed
          color="#01BDCB"
          class="white--text text-body-1"
          width="124"
          @click="$store.dispatch('app/setEnrollDrawer', true)"
        >
          一键预约
        </v-btn>
        <v-btn
          v-else
          type="primary"
          dense
          light
          depressed
          color="#01BDCB"
          class="white--text"
          width="124"
          @click="$store.dispatch('app/setEnrollHavedDrawer', true)"
        >
          <v-icon
            size="18"
            left
            class="mr-1"
          >
            {{ icons.gou }}
          </v-icon>
          已预约
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
import Tools from '@/mixins/Tools.js'
import Svgs from '@/mixins/Svgs.js'
import { getCookieForSymposia } from '@/util/helpers.js'
import {
  SYMPOSIA_MEETUP,
  DESKTOP_HEADER_HEIGHT,
  BODY_MAX_WIDTH
} from '@/constants.js'
export default {
  name: 'PcHeader',
  mixins: [Tools, Svgs],
  computed: {
    ...mapGetters(['scrolled', 'website_menu', 'was_enrolled']),
    styleObj() {
      return {
        height: DESKTOP_HEADER_HEIGHT,
        width: BODY_MAX_WIDTH
      }
    }
  },
  mounted() {
    if(getCookieForSymposia(SYMPOSIA_MEETUP)) {
      this.$store.dispatch('app/setWasEnrolled', true)
    } else {
      this.$store.dispatch('app/setWasEnrolled', false)
    }
  }
}
</script>
<style lang="sass" scoped>
.fixed-opacity
  position: fixed
  left: 0
  top: 0
  z-index: 2
  background-color: rgba(255, 255, 255, 1)
  box-shadow: 0 2px 8px 0 rgba(0,0,0,0.07)
.fixed-show
  background-color: rgba(255, 255, 255, 0.98)
.cs-menu
  border-bottom: solid 3px transparent
  cursor: pointer
  &:hover
    border-bottom: solid 3px #01BDCB
.cs-menu-active
  border-bottom: solid 3px #01BDCB
.cs-menu-active-a
    color: #01BDCB !important
.cs-button
  opacity: 1 !important
</style>
<style lang="sass">
.v-btn--plain:not(.v-btn--active):not(.v-btn--loading):not(:focus):not(:hover) .v-btn__content
  opacity: 1
</style>
