<template>
  <v-navigation-drawer
    :value="menu_drawer"
    fixed
    temporary
    mini-variant
    :mini-variant-width="enroll_drawer_width"
    :width="enroll_drawer_width"
    @update:mini-variant="handleMini"
    @input="handleInput"
  >
    <v-card class="fill-height d-flex flex-column">
      <v-card-text class="py-3 py-sm-6 flex-grow-1 overflow-y-auto">
        <div
          class="d-block py-3 py-sm-6"
        >
          <router-link
            to='/'
            class="text-decoration-none text-body-1"
            :class="[website_menu==='index' ? 'cs-menu-active-a': 'grey--text text--darken-4']"
          >
            首页
          </router-link>
        </div>
        <v-divider />
        <div
          class="d-block py-3 py-sm-6"
        >
          <router-link
            to='/playback'
            class="text-decoration-none text-body-1"
            :class="[website_menu==='playback' ? 'cs-menu-active-a': 'grey--text text--darken-4']"
          >
            回放
          </router-link>
        </div>
        <v-divider v-if="false" />
        <div
          v-if="false"
          class="d-block py-3 py-sm-6"
        >
          <router-link
            to='/liveroom'
            class="text-decoration-none text-body-1"
            :class="[website_menu==='liveroom' ? 'cs-menu-active-a': 'grey--text text--darken-4']"
          >
            直播间
          </router-link>
        </div>
        <v-divider />
        <div
          class="d-block py-3 py-sm-6"
        >
          <router-link
            to='/lecturer'
            class="text-decoration-none text-body-1"
            :class="[website_menu==='lecturer' ? 'cs-menu-active-a': 'grey--text text--darken-4']"
          >
            嘉宾
          </router-link>
        </div>
        <v-divider />
        <div
          class="d-block py-3 py-sm-6"
        >
          <router-link
            to='/agenda'
            class="text-decoration-none text-body-1"
            :class="[website_menu==='agenda' ? 'cs-menu-active-a': 'grey--text text--darken-4']"
          >
            议程
          </router-link>
        </div>
        <v-divider v-if="false" />
      </v-card-text>
    </v-card>
  </v-navigation-drawer>
</template>
<script>
import { mapGetters } from 'vuex'
import Tools from '@/mixins/Tools.js'
export default {
  name: 'MobileMenu',
  mixins: [Tools],
  computed: {
    ...mapGetters(['menu_drawer', 'enroll_drawer_width', 'website_menu'])
  },
  methods: {
    // mini和input双线合作，才能完全解决弹出/缩进的操作
    handleMini(bool) {
      this.$store.dispatch('app/setMenuDrawer', bool)
    },
    handleInput(bool) {
      if(!bool){
        this.$store.dispatch('app/setMenuDrawer', bool)
      }
    }
  }
}
</script>
<style lang="sass" scoped>
.cs-menu-active-a
  color: #01BDCB !important
</style>
